import { connect } from 'react-redux';
import { StoreState } from '../types/index';
import SubmissionEdit from '../components/SubmissionEdit';

const mapStateToProps = (state: StoreState) => ({
  userInfo: state.userInfo
});

const mapDispatchToProps = (dispatch: any) => ({
});

const SubmissionEditContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmissionEdit);

export default SubmissionEditContainer;
