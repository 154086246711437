import * as constants from '../constants';
import firebase from 'firebase';
import { db, auth } from '../firebase';
import { Dispatch } from 'redux';
import { StoreState, UserInfoDB, ResultType, UserResultType } from '../types/index';
import { formatAndAddUsersToResults } from '../utils/leaderboard';
import { createUserIfNotExists } from '../utils/auth';
import { getUserSubmissions } from '../utils/profile';

type TasksType = StoreState['taskList']['tasks'];
type ResultsType = StoreState['resultList']['results'];
type UserSubmissionType = StoreState['userInfo']['submissions'];

export interface AuthChangedLogin {
  type: constants.USER_HAS_LOGGED_IN;
  data: {user: UserInfoDB};
}

export interface AuthChangedLogout {
  type: constants.USER_HAS_LOGGED_OUT;
}

export type AuthChangedAction = AuthChangedLogin | AuthChangedLogout;

export function createAuthChangedAction(userInfo: UserInfoDB) {
  return {
    type: constants.USER_HAS_LOGGED_IN,
    data: {user: userInfo}
  };
}

export function authChanged(user: firebase.User | null) {
  if (user) {
    // Doing all this, so that we don't fetch privateButAddable always
    const userRef = db.ref('users').child(user.uid);
    const userPublicPromise = userRef.child('publicFields').once('value');
    const userPrivatePromise = userRef.child('privateFields').once('value');
    const userAdminPromise = userRef.child('isAdmin').once('value');
    const userBannedPromise = userRef.child('isBanned').once('value');

    const userPromises = [
      userPublicPromise, userPrivatePromise,
      userAdminPromise, userBannedPromise
    ];
    return function (dispatch: Dispatch<AuthChangedAction>) {
      return Promise.all(userPromises).then((values) => {
        const userInfo: UserInfoDB = {
          publicFields: values[0].val() || {
            avatarImageUrl: user.photoURL,
            displayName: user.displayName
          },
          privateFields: values[1].val() || {
            email: user.email,
            createdOn: Date.now(),
            googleId: user.uid
          },
          isAdmin: values[2].val() || false,
          isBanned: values[3].val() || false,
          publicButAddable: {}
        };

        dispatch(createAuthChangedAction(userInfo));
      });
    };
  } else {
    return {
      type: constants.USER_HAS_LOGGED_OUT
    };
  }
}

export interface LoginWithGoogle {
  type: constants.LOGIN_WITH_GOOGLE;
  data: {result: firebase.User};
}

export type LoginAction = LoginWithGoogle;

export function finishLoginWithGoogle(result: firebase.User) {
  return function (dispatch: Dispatch<LoginAction>) {
    return createUserIfNotExists().then(() => {
      const action = {
        type: constants.LOGIN_WITH_GOOGLE,
        data: {result}
      };
      dispatch(action);
    });
  };
}

export interface Logout {
  type: constants.LOGOUT_USER;
}

export type LogoutAction = Logout;

export function logout(): LogoutAction {
  return {
    type: constants.LOGOUT_USER
  };
}

export function logoutUser() {
  return function (dispatch: Dispatch<LogoutAction>) {
    return auth.signOut().then(() => {
      dispatch(logout());
    });
  };
}

export interface TasksRequested {
  type: constants.TASKS_REQUESTED;
}

export interface TasksFetched {
  type: constants.TASKS_FETCHED;
  data: {result: TasksType};
}

export type TasksAction = TasksRequested | TasksFetched;

export function requestTasks() {
  return {
    type: constants.TASKS_REQUESTED
  };
}

export function receiveTasks(result: TasksType) {
  return {
    type: constants.TASKS_FETCHED,
    data: {result: result || {}}
  };
}

export function fetchTasks() {
  return function (dispatch: Dispatch<TasksAction>) {
    dispatch(requestTasks());

    const ref = db.ref('tasks');

    return ref.once('value').then(
      (results) => {
        dispatch(receiveTasks(results.val()));
      },
      (error: any) => {
        console.log(error);
      }
    );
  };
}

export interface StartCanUploadCheck {
  type: constants.START_CAN_UPLOAD_CHECK;
}

export interface FinishCanUploadCheck {
  type: constants.FINISH_CAN_UPLOAD_CHECK;
  data: boolean;
}

export type CanUploadCheckAction = StartCanUploadCheck | FinishCanUploadCheck;

export function startCanUploadCheck(): StartCanUploadCheck {
  return {
    type: constants.START_CAN_UPLOAD_CHECK
  };
}

export function finishCanUploadCheck(result: boolean): FinishCanUploadCheck {
  return {
    type: constants.FINISH_CAN_UPLOAD_CHECK,
    data: result
  };
}

const twoDays = 24 * 60 * 60 * 1000;

export function doCanUploadCheck() {
  return function (dispatch: Dispatch<CanUploadCheckAction>) {
    dispatch(startCanUploadCheck());
    if (!auth.currentUser) {
      dispatch(finishCanUploadCheck(false));
      return;
    }

    const prevSubmissionsRef = db.ref()
    .child(`users/${auth.currentUser!.uid}/publicButAddable/submissions`)
    .orderByChild('successful')
    .equalTo(true)
    .limitToLast(2);

    return prevSubmissionsRef.once('value').then(
        (snapshot: firebase.database.DataSnapshot) => {
          const submissions = snapshot.val();

          if (!submissions || Object.keys(submissions).length <= 1) {
            dispatch(finishCanUploadCheck(true));
            return;
          }
          const secondLast = submissions[Object.keys(submissions)[0]];

          if (Date.now() - secondLast.createdOn > twoDays) {
            dispatch(finishCanUploadCheck(true));
          } else {
            dispatch(finishCanUploadCheck(false));
          }
        },
        (error) => {
          console.log(error);
          dispatch(finishCanUploadCheck(false));
        }
      );

  };
}

export interface ResultsRequested {
  type: constants.RESULTS_REQUESTED;
}

export interface ResultsFetched {
  type: constants.RESULTS_FETCHED;
  data: {result: ResultsType};
}

export type ResultsAction = ResultsRequested | ResultsFetched;

export function requestResults() {
  return {
    type: constants.RESULTS_REQUESTED
  };
}

export function receiveResults(result: ResultsType) {
  return {
    type: constants.RESULTS_FETCHED,
    data: {result}
  };
}

type ResultPromiseWithIdType = {
  val: firebase.database.DataSnapshot;
  id: string;
};

export function fetchResults(lastDay: boolean = false) {
  return function (dispatch: Dispatch<ResultsAction>) {
    dispatch(requestResults());

    let ref;
    if (lastDay) {
      ref = db.ref('results').orderByChild('macroScore').limitToLast(400);
      return ref.once('value').then((snapshot) => {
        const resultIds = Object.keys(snapshot.val()).reverse();
        const promises = resultIds.map((id) => {
          return new Promise((res, rej) => {
            db.ref(`results/${id}`).once('value').then(
              (val) => {
                res({val, id});
              },
              (err) => {
                rej(err);
              }
            );
          });
        });

        return Promise.all(promises);
      }).then((resolved: ResultPromiseWithIdType[]) => {
        const results = {};
        resolved.forEach(snapshot => {
          if (!snapshot) {
            return;
          }

          if (lastDay && Object.keys(results).length > 50) {
            return;
          }

          if (lastDay) {
            if (snapshot.val.val().successful === true) {
              results[snapshot.id] = snapshot.val.val();
              results[snapshot.id].submissionId = snapshot.id;
            }
          } else {
            results[snapshot.id] = snapshot.val.val();
            results[snapshot.id].submissionId = snapshot.id;
          }

        });

        return formatAndAddUsersToResults(results, lastDay).then(output => {
            dispatch(receiveResults(output));
        });      
      });

    } else {
      ref = db.ref('leaderboard').child('glue').orderByKey().limitToLast(1);
      return ref.once('value').then((snapshot: firebase.database.DataSnapshot) => {
        const results: UserResultType[] = Object.values(snapshot.val())[0] as UserResultType[];
        const filtered: any = results.filter((result) => {
          if (result.maxScore.toString() !== '-' && result.maxScore * 100 < 70) {
            return false;
          } else {
            return true;
          }
        });
        dispatch(receiveResults(filtered));
      });
    }
  };
}

export interface StartFetchUserSubmission {
  type: constants.START_FETCH_USER_SUBMISSION;
}

export interface FinishFetchUserSubmission {
  type: constants.FINISH_FETCH_USER_SUBMISSION;
  data: UserSubmissionType;
}

export type UserSubmissionAction = constants.START_FETCH_USER_SUBMISSION | constants.FINISH_FETCH_USER_SUBMISSION;

export function startFetchUserSubmission() {
  return {
    type: constants.START_FETCH_USER_SUBMISSION
  };
}

export function finishFetchUserSubmission(data: UserSubmissionType) {
  return {
    type: constants.FINISH_FETCH_USER_SUBMISSION,
    data: data
  };
}

export function fetchUserSubmissions(userId: string) {
  return function (dispatch: Dispatch<UserSubmissionAction>) {
    dispatch(startFetchUserSubmission());

    return getUserSubmissions(userId).then((submissions) => {
      dispatch(finishFetchUserSubmission(submissions));
    });
  };
}

export interface StartFetchSubmission {
  type: constants.START_FETCH_SUBMISSION;
}

type FinishFetchSubmissionDataType = {
  data: ResultType;
  id: string;
};

export interface FinishFetchSubmission {
  type: constants.FINISH_FETCH_SUBMISSION;
  data: FinishFetchSubmissionDataType;
}

export type FetchSubmissionAction = constants.START_FETCH_USER_SUBMISSION | constants.FINISH_FETCH_USER_SUBMISSION;

export function startFetchSubmission() {
  return {
    type: constants.START_FETCH_SUBMISSION
  };
}

export function finishFetchSubmission(data: FinishFetchSubmissionDataType) {
  return {
    type: constants.FINISH_FETCH_SUBMISSION,
    data: data
  };
}

export function fetchSubmission(submissionId: string) {
  return function (dispatch: Dispatch<FetchSubmissionAction>) {
    dispatch(startFetchSubmission());

    return db.ref(`results/${submissionId}`).once('value').then((snapshot) => {
      dispatch(finishFetchSubmission({data: snapshot.val(), id: submissionId}));
    });
  };
}

export interface UpdateSelectedUser {
  type: constants.UPDATE_SELECTED_USER;
  data: number;
}

export type UpdateSelectedUserAction = UpdateSelectedUser;

export function updateSelectedUser(index: number): UpdateSelectedUserAction {
  return {
    type: constants.UPDATE_SELECTED_USER,
    data: index
  };
}

export interface ChangeTheme {
  type: constants.CHANGE_THEME;
}

export type ChangeThemeAction = ChangeTheme;

export function changeTheme(): ChangeThemeAction {
  return {
    type: constants.CHANGE_THEME
  };
}
