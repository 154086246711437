import { db } from '../firebase';
import { UserInfoDB, UserResultType, ResultType } from '../types';

type AllResultsFromDBType = {
  [key: string]: ResultType;
};

type InitialSingleUserResultsType = {
  allSubmissions: ResultType[];
  displayName: string;
  avatarImageUrl: string;
};

export type UserwiseCollectedResultsType = {
  [key: string]: InitialSingleUserResultsType
};

export function formatAndAddUsersToResults(results: AllResultsFromDBType, lastDay: boolean = false) {
  const uniqueUsers: UserwiseCollectedResultsType = {};
  // const currentTimestamp = (new Date()).getTime();

  for (let key in results) {
    if (results.hasOwnProperty(key)) {
      const result = results[key];
      if (!result) {
        continue;
      }

      if (!result.successful) {
        continue;
      }

      // if (lastDay && currentTimestamp - parseInt(result.createdOn, 10) > 1 * 24 * 60 * 60 * 1000) {
      //   continue;
      // }

      if (!uniqueUsers[result.uploaderUid]) {
        uniqueUsers[result.uploaderUid] = {
          allSubmissions: [result],
          displayName: '',
          avatarImageUrl: ''
        };
      } else {
        uniqueUsers[result.uploaderUid].allSubmissions.push(result);
      }
    }
  }

  const promises: Promise<any>[] = [];

  Object.keys(uniqueUsers).forEach((userId) => {
    let dbPromise = db.ref().child(`users/${userId}/publicFields`)
                      .once('value');
    let promise = new Promise((resolve, reject) => {
      dbPromise.then(
        (value) => resolve({value, userId}),
        (error) => reject(error)
      );
    });
    promises.push(promise);
  });

  return Promise.all(promises)
  .then(values => values.filter(value => !(value instanceof Error)))
  .then(values => {
    values.forEach((value, key) => {
      let userInfo: UserInfoDB['publicFields'] = value.value.val();

      if (!userInfo) {
        console.log(userInfo);
        userInfo = {
          displayName: '',
          avatarImageUrl: ''
        };
      }
      uniqueUsers[value.userId] = {
        ...uniqueUsers[value.userId],
        displayName: userInfo.displayName!,
        avatarImageUrl: userInfo.avatarImageUrl!
      };
    });

    return sortAndFormat(uniqueUsers);
  });
}

export function sortAndFormat(uniqueUsers: UserwiseCollectedResultsType): UserResultType[] {
  const finalUsersResultsSorted: UserResultType[] = [];

  Object.keys(uniqueUsers).forEach((key) => {
    const initialUserResults: InitialSingleUserResultsType = uniqueUsers[key];
    let allSubmissions: ResultType[] = initialUserResults.allSubmissions;

    allSubmissions.sort(sortSubmissionAccordingToScore);
    const maxScoreSubmission: ResultType = allSubmissions[0];
    allSubmissions.shift();

    const finalUserResult: UserResultType = {
      displayName: initialUserResults.displayName,
      avatarImageUrl: initialUserResults.avatarImageUrl,
      maxScore: maxScoreSubmission.macroScore,
      maxScoreSubmission,
      otherSubmissions: allSubmissions
    };

    finalUsersResultsSorted.push(finalUserResult);
  });

  finalUsersResultsSorted.sort(sortUsersAccordingToMaxScore);
  return finalUsersResultsSorted;
}

export function sortSubmissionAccordingToScore(a: ResultType,
                                               b: ResultType): number {
  if (a.macroScore.toString() === '-') {
    return 1;
  }
  if (b.macroScore.toString() === '-') {
    return -1;
  }
  if (a.macroScore > b.macroScore) {
    return -1;
  } else if (a.macroScore < b.macroScore) {
    return 1;
  } else {
    return 0;
  }
}

export function sortUsersAccordingToMaxScore(a: UserResultType,
                                             b: UserResultType): number {
  if (a.maxScore.toString() === '-') {
    return 1;
  }
  if (b.maxScore.toString() === '-') {
    return -1;
  }

  if (a.maxScore > b.maxScore) {
    return -1;
  } else if (a.maxScore < b.maxScore) {
    return 1;
  } else {
    return 0;
  }
}
