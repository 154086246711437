import * as React from 'react';
import FileInput from './FileInput';

import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import { STRING_CONSTANTS } from '../constants';
import { submitInfoListItemStyle, submitFormStyles } from '../styles';
import { RenderTextField } from './AddTask';
import ListItem from '@material-ui/core/ListItem';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
const loading = require('../loading.svg');

class SubmitForm extends React.Component<any, any> {
  state = {
    open: 'none',
    anchorEl: null
  };

  messages = {
    'name': 'A short name for your system, which will be displayed in the leaderboard. (Required)',
    'url': 'A URL for a paper or (if one is not available) website or \ncode repository describing your system.',
    'modelDescription': 'A sentence or two describing your system. Make sure to mention any outside' +
                        '\ndata or resources you use. (Required)',
    'parameterDescription': 'A sentence or explaining how you share parameters across tasks' +
                            '\n(or stating that you don\'t share parameters). (Required)',
    'totalParameters': 'The total number of trained parameters in your model.' +
                        '\nDo not count word or word-part embedding parameters, even if they are trained.',
    'sharedParameters': 'The total number of trained parameters in your model that are shared across' +
                        '\nmultiple task models. If some parameters are shared across some but not all' +
                        '\nof your tasks, count those. Do not count word or word-part embedding parameters,' +
                        '\neven if they are trained.',
    'public': 'Whether you want your submission to be visible on the public leaderboard.'

  };

  handleClick = (e: React.MouseEvent<any>, field) => {
    this.setState({
      open: field,
      anchorEl: e.currentTarget
    });
  }

  handleRequestClose = () => {
    this.setState({
      open: 'none',
      anchorEl: null
    });
  }

  render() {
    const { handleSubmit, submitSucceeded, submitting } = this.props;
    const isEditForm = !!this.props.isEditForm;
    const handleClick = this.handleClick.bind(this);
    if (submitting) {
      return (
        <div className={this.props.classes.form}>
          <p>Submitting...</p>
          <img src={loading} className="App-logo" alt="Loading" />
        </div>
      );
    } else if (!submitSucceeded) {
      return (
        <form
          onSubmit={handleSubmit}
          className={this.props.classes.form}
        >
          {
            !isEditForm ?
            'Please make sure that you are submitting your results with newer version of QNLI.'
            : ''
          }
          <br/>
          <Popper
            open={Boolean(this.state.anchorEl)}
            placement="right-start"
            anchorEl={this.state.anchorEl}
          >
            <Paper>
              <ListItem
                style={submitInfoListItemStyle}
              >
                {this.messages[this.state.open]}

              </ListItem>
            </Paper>
          </Popper>
          <Field
            name="name"
            component={RenderTextField}
            maxLength={50}
            label="Submission Name*"
            focus={(e) => handleClick(e, 'name')}
            blur={() => this.handleRequestClose()}
          />
          <br/>
          <Field
            name="url"
            component={RenderTextField}
            maxLength={200}
            label="Model URL/Github"
            focus={(e) => handleClick(e, 'url')}
            blur={() => this.handleRequestClose()}
          />
          <br/>
          <Field
            name="modelDescription"
            multiline={true}
            component={RenderTextField}
            label="Model Description*"
            rowsMax={4}
            maxLength={500}
            focus={(e) => handleClick(e, 'modelDescription')}
            blur={() => this.handleRequestClose()}
          />
          <br/>
          <Field
            name="parameterDescription"
            multiline={true}
            component={RenderTextField}
            rowsMax={4}
            maxLength={500}
            label="Parameter sharing description*"
            focus={(e) => handleClick(e, 'parameterDescription')}
            blur={() => this.handleRequestClose()}
          />
          <br/>
          <Field
            name="totalParameters"
            maxLength={50}
            component={RenderTextField}
            type="number"
            label="Total number of parameters"
            focus={(e) => handleClick(e, 'totalParameters')}
            blur={() => this.handleRequestClose()}
          />
          <br/>
          <Field
            name="sharedParameters"
            maxLength={50}
            component={RenderTextField}
            type="number"
            label="Shared number of parameters"
            focus={(e) => handleClick(e, 'sharedParameters')}
            blur={() => this.handleRequestClose()}
          />
          <br/>
          <br/>
          <Field
            name="public"
            component="input"
            type="checkbox"
            label="Public?"
          />
          <label> Public?</label>
          {
            !isEditForm ? (
              <div>
                <br/>
                <br/>
                <Field
                  type="file"
                  name="file"
                  component={FileInput}
                  label={STRING_CONSTANTS.SELECT_ZIP_LABEL}
                  accept=".zip"
                />
              </div>
              ) : ''
            }
          <br/>
          <br/>
          <Button
            variant="contained"
            color="primary"
            disabled={submitting}
            type="submit"
          >
            {isEditForm ? 'Update' : 'Submit'}
          </Button>
        </form>
      );
    } else {
      return (
        <div className={this.props.classes.form}>
          <span>{isEditForm ? 'Update' : 'Submit'} Successful</span>
          <br/>
          <Button
            variant="contained"
            color="primary"
            onClick={() => { window.location.reload(); }}
          >
            Reset
          </Button>
        </div>
      );
    }
  }
}

let SubmitReduxForm = reduxForm({
  form: 'submitForm'
})(withStyles(submitFormStyles)(SubmitForm));

export const SubmitComponent = withStyles(submitFormStyles)(SubmitForm);

export default SubmitReduxForm;
