import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#2b5283',
      main: '#2b5283',
      dark: '#2b5283',
      contrastText: '#fff',
    },
    secondary: {
      light: '#bd492a',
      main: '#e65f57',
      dark: '#e65f57',
      contrastText: '#000',
    }
  },
  typography: {
    useNextVariants: true,
  },
});

export const alignCenterStyle = {
  textAlign: 'center' as 'center',
  fontSize: '1em'
};

export const boldStyle = {
  textAlign: 'center' as 'center',
  fontWeight: 'bold' as 'bold',
  // fontSize: '1em',
};

export const tabStyle = {
  ...boldStyle,
  border: '1px solid #494647'
};

export const fontIconStyle = {
  marginRight: '10px',
  cursor: 'pointer' as 'pointer'
};

export const fontIconUrlStyle = {
  cursor: 'pointer' as 'pointer'
};

export const linkStyle = {
  display: 'flex' as 'flex',
  alignItems: 'center' as 'center',
  justifyContent: 'center' as 'center',
};

export const rowColumnStyle = {
  textAlign: 'center' as 'center',
  textOverflow: 'inherit' as 'inherit',
};

export const submitInfoListItemStyle = {
  whiteSpace: 'pre' as 'pre',
  lineHeight: '1.2'
};

export const textRowColumnStyle = {
  ...rowColumnStyle,
  textAlign: 'left' as 'left'
};

export const numberRowColumnStyle = {
  ...rowColumnStyle,
  textAlign: 'right' as 'right' as 'right'
};

export const multiLineLabelStyle = {
  width: '100%',
  left: '0px',
  textAlign: 'left' as 'left'
};

export const overflowHidden = {
  overflow: 'hidden' as 'hidden'
};

export const introStyles = (themeObject: any) => ({
  logo: {
    width: '100%',
    borderBottom: '1px dotted transparent',
    '&:hover': {
      textDecoration: 'none',
      border: 'none'
    }
  },
  firstLogo: {
    marginLeft: '10%',
    [themeObject.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  buttonLinks: {
    '& a': {
      borderBottom: '1px dotted transparent'
    }
  },
  logoContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    paddingTop: '2em'
  },
  logoImage: {
    width: '100%'
  },
  glueName: {
    textAlign: 'left' as 'left',
    color: themeObject.palette.primary.main,
    fontSize: '3.5em',
    [themeObject.breakpoints.up('sm')]: {
      fontSize: '4em'
    },
    [themeObject.breakpoints.up('md')]: {
      fontSize: '4.8em'
    },
    [themeObject.breakpoints.up('lg')]: {
      fontSize: '6em'
    }
  },
  glueLogoContainer: {
    textAlign: 'right' as 'right',
  },
  glueLogo: {
    color: themeObject.palette.primary.main,
    width: '4.75em',
    [themeObject.breakpoints.up('sm')]: {
      width: '5.75em'
    },
    [themeObject.breakpoints.up('md')]: {
      width: '6.75em'
    },
    [themeObject.breakpoints.up('lg')]: {
      width: '7.75em'
    }
  }
});

export const headerStyles = (themeObject: any) => ({
  fontIcon: {
    marginRight: '10px',
    cursor: 'pointer' as 'pointer',
    textAlign: 'center' as 'center',
    color: themeObject.palette.primary.contrastText,
    [themeObject.breakpoints.down('md')]: {
      color: themeObject.palette.primary.main
    },
  },
  superglueIcon: {
    height: '1.15em',
    verticalAlign: 'sub',
    [themeObject.breakpoints.down('md')]: {
      height: '1em'
    }
  },
  glueIcon: {
    height: '1.15em',
    verticalAlign: 'sub',
    paddingRight: '0.2em',
    [themeObject.breakpoints.down('md')]: {
      height: '1em'
    }

  },
  link: {
    display: 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between',
    marginRight: '10px',
    color: themeObject.palette.primary.contrastText,
    fontSize: '0.8em',
    '&:hover': {
      textDecoration: 'none',
      color: themeObject.palette.secondary.main
    }
  },
  sectionDesktop: {
    display: 'none',
    [themeObject.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [themeObject.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
    justifyContent: 'flex-start'
  },
  buttonLink: {
    color: themeObject.palette.primary.contrastText,
    textDecoration: 'none',
    marginRight: '0.9em',
    '&:hover': {
      color: themeObject.palette.secondary.main
    },
    '&:hover .icon-white': {
      display: 'none'
    },
    '&:hover .icon-red': {
      display: 'inline-block'
    },
    [themeObject.breakpoints.down('md')]: {
      fontSize: '0.8em',
      marginRight: '0.4em',
    }
  },
});

export const submitFormStyles = (themeObject) => ({
  form: {
    width: '40%',
    margin: '0 auto',
    textAlign: 'center' as 'center',
    [themeObject.breakpoints.down('md')]: {
      width: '90%'
    }
  }
});

export const tableStyles = (themeObject) => ({
  th: {
    ...boldStyle,
    backgroundColor: themeObject.palette.primary.main,
    color: themeObject.palette.primary.contrastText
  },
  fontIcon: {
    ...fontIconStyle,
    color: themeObject.palette.primary.main,
    borderBottom: '1px dotted ' + themeObject.palette.primary.main
  },
  successIcon: {
    ...fontIconStyle,
    color: 'green',
  },
  errorIcon: {
    ...fontIconStyle,
    color: themeObject.palette.secondary.main
  },
  fontIconSidebar: {
    ...fontIconStyle,
    color: themeObject.palette.primary.main,
    fontSize: '1em',
    borderBottom: '1px dotted ' + themeObject.palette.primary.main
  },
  table: {
    borderRadius: '4px'
  },
  tr: {
    borderBottom: '1px solid ' + themeObject.palette.secondary.contrastText,
    ...alignCenterStyle,
    '& a:hover': {
      borderBottom: '1px solid transparent'
    },
    '& a': {
      borderBottom: '1px solid transparent'
    }
  },
  textTr: {
    borderBottom: '1px solid ' + themeObject.palette.secondary.contrastText,
    ...alignCenterStyle,
    textAlign: 'left' as 'left',
    '& a:hover': {
      borderBottom: '1px solid transparent'
    },
    '& a': {
      borderBottom: '1px solid transparent'
    }
  },
  plusTr: {
    borderBottom: '1px solid ' + themeObject.palette.secondary.contrastText,
    ...alignCenterStyle,
    textAlign: 'left' as 'left',
    width: '0.2em',
    paddingLeft: '15px',
    '& a:hover': {
      borderBottom: '1px solid transparent'
    },
    '& a': {
      borderBottom: '1px solid transparent'
    },
    '& span': {
      borderBottom: '1px solid transparent'
    }
  },
  numberTr: {
    borderBottom: '1px solid ' + themeObject.palette.secondary.contrastText,
    ...alignCenterStyle,
    textAlign: 'right' as 'right',
    '& a:hover': {
      borderBottom: '1px solid transparent'
    },
    '& a': {
      borderBottom: '1px solid transparent'
    }
  },
  textTh: {
    ...boldStyle,
    backgroundColor: themeObject.palette.primary.main,
    color: themeObject.palette.primary.contrastText,
    textAlign: 'left' as 'left'
  },
  plusTh: {
    ...boldStyle,
    backgroundColor: themeObject.palette.primary.main,
    color: themeObject.palette.primary.contrastText,
    textAlign: 'left' as 'left',
    width: '0.2em',
    paddingLeft: '15px'

  },
  numberTh: {
    ...boldStyle,
    backgroundColor: themeObject.palette.primary.main,
    color: themeObject.palette.primary.contrastText,
    textAlign: 'right' as 'right'
  },
  listItem: {
    display: 'block'
  },
  listItemText: {
    display: 'inline-block'
  }
});

export const drawerStyles = (themeObject: any) => ({
  paper: {
    width: '15%',
    [themeObject.breakpoints.down('sm')]: {
      width: '80%'
    },
    [themeObject.breakpoints.down('md')]: {
      width: '50%'
    }
  },
});

export const aboutStyles = (themeObject: any) => ({
  listItem: {
    cursor: 'pointer'
  }
});
