import * as React from 'react';
import Submit from './Submit';
import firebase from 'firebase';
import { db, storage } from '../firebase';
import { SubmissionError } from 'redux-form';
import { StoreState } from '../types';
import NotReady from './NotReady';
import { overflowHidden } from '../styles';
const loading = require('../loading.svg');

interface Props {
  userInfo: StoreState['userInfo'];
  submission: StoreState['submission'];
  doCanUploadCheck: () => void;
}

// TODO: Fix the reset check for twice submission
// TODO: Maybe test the files in zip have TSV for each task here itself.
class SubmitForm extends React.Component<Props, {}> {
  submit = (values: any) => {

    const valuesNeeded = ['name', 'modelDescription',
    'parameterDescription', 'file'];

    // Check for all fields
    valuesNeeded.forEach((item) => {
      if (typeof(values[item]) === 'undefined') {
        throw new SubmissionError({_error: 'Missing ' + item});
      }
    });

    const submissionId = db.ref().child('submissions').push().key;
    if (!submissionId) {
      throw SubmissionError('Connection to DB failed');
    }
    return this.uploadZip(values, submissionId).then(
      () => this.addSubmissionToUser(submissionId).then(
        () => this.addSubmissionToDB(values, submissionId).then(
          () => console.log('Upload successful'),
          () => this.throwError('Add to DB failed')
        ),
        () => this.throwError('Add to DB failed')
      ),
      () => this.throwError('Upload failed')
    );
  }

  componentDidMount() {
    this.props.doCanUploadCheck();
  }

  throwError = (err: string) => {
    throw new SubmissionError({_error: err});
  }

  isValidSubmission = () => {
    return this.props.userInfo.isAuthenticated;
  }

  addSubmissionToUser = (submissionId: string) => {
    const data = {
      createdOn: firebase.database.ServerValue.TIMESTAMP
    };

    const userSubmissionRef = db.ref().child(`users/${this.props.userInfo.uid}`)
    .child(`publicButAddable/submissions/${submissionId}`);

    return userSubmissionRef.set(data);
  }

  addSubmissionToDB = (values: any, submissionId: string) => {
    const storagePath: string = `submissions/${submissionId}.zip`;

    if (typeof (values.totalParameters) === 'undefined') {
      values.totalParameters = -1;
    }
    if (typeof (values.sharedParameters) === 'undefined') {
      values.sharedParameters = -1;
    }

    const uploadData = {
      uploaderUid: this.props.userInfo.uid,
      filePath: storagePath,
      name: values.name,
      url: values.url || '',
      public: !!values.public,
      modelDescription: values.modelDescription,
      parameterDescription: values.parameterDescription,
      totalParameters: parseInt(values.totalParameters, 10),
      sharedParameters: parseInt(values.sharedParameters, 10),
      createdOn: firebase.database.ServerValue.TIMESTAMP,
      isGraded: false
    };

    const submissionRef = db.ref().child(`submissions/${submissionId}`);

    return submissionRef.set(uploadData);
  }

  uploadZip = (values: any, submissionId: string) => {
    return new Promise((resolve, reject) => {
      if (!this.isValidSubmission()) {
        reject('You need to be authenticated to upload');
      }

      const zipFolderRef = storage.ref().child('submissions');
      const zipName = submissionId + '.zip';

      const zipRef = zipFolderRef.child(zipName);

      const metadata = {
        customMetadata: {
          uploaderUid: this.props.userInfo.uid,
          originalName: values.file.name,
          uploaderEmail: this.props.userInfo.email
        }
      } as firebase.storage.SettableMetadata;

      zipRef.put(values.file, metadata).then(
        (snapshot: firebase.storage.UploadTaskSnapshot) => {
          resolve(snapshot);
        },
        () => {
          reject('Zip upload failed');
        }
      );
    });
  }

  render() {
    if (!this.props.userInfo.isSiteReady) {
      return <NotReady/>;
    }
    if (this.props.submission.isChecking) {
      return (
        <div className="submission-container" style={overflowHidden}>
          <p>Checking whether you can upload</p>
          <img src={loading} className="App-logo" alt="Loading" />
        </div>
      );
    } else {
      // if (this.props.submission.canUpload) {
        return (
          <Submit onSubmit={this.submit} />
        );
      // } else {
        // return (
        //   <div className="submission-container">You can't upload more than two times a day</div>
        // );
      // }
    }
  }
}

export default SubmitForm;
