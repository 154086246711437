import * as React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import AppContainer from './containers/AppContainer';
import { store } from './stores/index';

const config = require('./config.json');

export const AppRouter: React.StatelessComponent<{}> = () => {
  return (
      <Provider store={store}>
        <BrowserRouter basename={config.basename || '/'}>
          <div>
            <Route path="/" component={AppContainer} />
          </div>
        </BrowserRouter>
      </Provider>
  );
};
