import { connect } from 'react-redux';
import { StoreState } from '../types/index';
import AddTaskForm from '../components/AddTaskForm';

const mapStateToProps = (state: StoreState) => ({
  userInfo: state.userInfo
});

const AddTaskFormContainer = connect(
  mapStateToProps)(AddTaskForm);

export default AddTaskFormContainer;
