import * as React from 'react';
const loading = require('../loading.svg');
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { auth, googleProvider } from '../firebase';
import firebase from 'firebase';
import { Redirect } from 'react-router';
import { StoreState } from '../types/index';

interface Location {
  state: {
    from: string;
  };
}

type Props = {
  userInfo: StoreState['userInfo'];
  general: StoreState['general'];
  location: Location;
  dispatchFinishLoginWithGoogle(result: firebase.User | null): void;
};

class Login extends React.Component<Props, {}> {
  loginUserWithGoogle() {
    auth.signInWithPopup(googleProvider);
  }

  componentDidMount() {
    auth.onAuthStateChanged((result) => {
      this.props.dispatchFinishLoginWithGoogle(result);
    });
  }

  render() {
    const { from } = this.props.location.state || { from: '/' };
    if (this.props.userInfo.isAuthenticated && this.props.userInfo.isBanned) {
      return (
        <div>
          <div>Unfortunately, you have been banned from GLUE and SuperGLUE benchmarks.</div>
          <div>Contact admins at glue-benchmark-admin [at] googlegroups [dot] com for more information.</div>
        </div>
      );
    }
    if (this.props.userInfo.isAuthenticated && !this.props.userInfo.redirectToReferrer) {
      return <Redirect to="/" />;
    }

    if (this.props.userInfo.isCheckingLogin) {
      return (
        <div className="centered-container">
          <img src={loading} className="App-logo" alt="Loading" />;
        </div>
      );
    }
    return (
      <div className="centered-container">
        {this.props.userInfo.redirectToReferrer && <Redirect to={from || '/'} />}
        <Button
          variant="contained"
          color="secondary"
          style={{
            color: '#fff'
          }}
          onClick={this.loginUserWithGoogle}
        >
          <Icon style={{ marginRight: '0.5em' }} className="fa fa-google" />
          Sign in with Google
        </Button>
      </div>
    );
  }
}

export default Login;
