import * as React from 'react';
import AddTask from './AddTask';
import firebase from 'firebase';
import { db, storage } from '../firebase';
import { SubmissionError } from 'redux-form';
import { StoreState } from '../types';

interface Props {
  userInfo: StoreState['userInfo'];
}

// TODO: Make sure identifier is unique
// TODO: Extract this out into separate file.
// TODO: Replace strings with string constants
class AddTaskForm extends React.Component<Props, {}> {
  submit = (values: any) => {
    if (!this.props.userInfo.isAdmin) {
      throw new SubmissionError({_error: 'You need to be admin'});
    }

    const valuesNeeded = ['identifier', 'name', 'metric', 'type', 'testTSV', 'downloadURL'];

    // Check for all fields
    valuesNeeded.forEach((item) => {
      if (!values[item]) {
        throw new SubmissionError({_error: 'Missing ' + item});
      }
    });

    values.weight = parseFloat(values.weight);
    const tasksAdminInfoRef = db.ref('tasksAdminInfo');
    const taskId = tasksAdminInfoRef.push().key as string;

    // First upload the tsv
    return this.uploadTaskTSV(values, taskId).then(
      () => {
        // Then add tsv to DB
        return this.addTaskTSVToDB(tasksAdminInfoRef, taskId).then(
          // Finally add task to DB
          () => this.addTaskToDB(values, taskId).then(
            () => console.log('Uploaded'),
            (error: string) => this.throwError('Add to DB failed', error)
          ),
          (error: string) => this.throwError('Add to DB failed', error)
        );
      },
      (error: string) => this.throwError('Upload failed', error)
    );
  }

  addTaskTSVToDB = (ref: firebase.database.Reference, taskId: string) => {
      return ref.child(taskId).set({
        tsvPath: `tasksTSV/${taskId}.tsv`
      });
  }

  addTaskToDB = (values, taskId: string) => {
      delete values.testTSV;
      const tasksRef = db.ref('tasks');
      values.tsvWithoutLabels = 'https://not_uploaded_yet';
      return tasksRef.child(taskId).set(values);
  }

  throwError = (errorMsg: string = 'Failed', error: any) => {
    throw new SubmissionError({message: errorMsg, _error: error});
  }

  isValidSubmission = () => {
    return this.props.userInfo.isAuthenticated && this.props.userInfo.isAdmin;
  }

  uploadTaskTSV = (values: any, taskId: string) => {
    return new Promise((resolve, reject) => {
      if (!this.isValidSubmission()) {
        reject('You need to be an admin to upload');
      }

      const tasksTSVFolderRef = storage.ref().child('tasksTSV');
      const taskTSVName = taskId + '.tsv';

      const tsvRef = tasksTSVFolderRef.child(taskTSVName);

      const metadata = {
        customMetadata: {
          uploaderUid: this.props.userInfo.uid,
          originalName: values.testTSV.name,
          uploaderEmail: this.props.userInfo.email
        }
      } as firebase.storage.SettableMetadata;

      tsvRef.put(values.testTSV, metadata).then(
        (snapshot: firebase.storage.UploadTaskSnapshot) => {
          resolve(snapshot);
        },
        () => {
          reject('TSV upload failed');
        }
      );
    });
  }

  render() {
    return (
      <AddTask
        initialValues={{weight: 1}}
        onSubmit={this.submit}
      />
    );
  }
}

export default AddTaskForm;
