import * as React from 'react';
import {
  AppBar, Toolbar, Icon, Menu, withStyles, Link,
  ClickAwayListener, IconButton, MenuList
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { StoreState } from '../types/index';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVert from '@material-ui/icons/MoreVert';

import { headerStyles } from '../styles';

interface HeaderProps {
  userInfo: StoreState['userInfo'];
  general: StoreState['general'];
  history?: any;
  classes: any;
  onLogout(): void;
}

interface MobileMenuProps {
  mobileMoreAnchorEl: HTMLElement | null;
  isMobileMenuOpen: boolean;
  handleMenuClose: any;
  classes: any;
  userInfo: StoreState['userInfo'];
  onLogout(): void;
}

const MobileMenu: React.StatelessComponent<MobileMenuProps> = (props) => {
  return (
    <Menu
      anchorEl={props.mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={props.isMobileMenuOpen}
      onClose={props.handleMenuClose}
    >
      <ClickAwayListener onClickAway={props.handleMenuClose}>
        <MenuList onClick={props.handleMenuClose}>
          <Link
            href="https://openreview.net/pdf?id=rJ4km2R5t7"
            className={props.classes.link}
          >
            <MenuItem>
              <Icon className={'fa fa-file ' + props.classes.fontIcon} />
              Paper
              </MenuItem>
          </Link>
          <Link
            href="https://github.com/nyu-mll/GLUE-baselines"
            className={props.classes.link}
          >
            <MenuItem>
              <Icon className={'fa fa-code ' + props.classes.fontIcon} />
              Code
              </MenuItem>
          </Link>
          <NavLink activeClassName="active-link" to="/tasks" className={props.classes.link}>
            <MenuItem>
              <Icon className={'fa fa-tasks ' + props.classes.fontIcon} />
              Tasks
            </MenuItem>
          </NavLink>

          <NavLink activeClassName="active-link" to="/leaderboard" className={props.classes.link}>
            <MenuItem>
              <Icon className={'fa fa-trophy ' + props.classes.fontIcon} />
              Leaderboard
            </MenuItem>
          </NavLink>
          <NavLink activeClassName="active-link" to="/faq" className={props.classes.link}>
            <MenuItem>
              <Icon className={'fa fa-info ' + props.classes.fontIcon} />
              FAQ
            </MenuItem>
          </NavLink>
          <NavLink activeClassName="active-link" to="/diagnostics" className={props.classes.link}>
            <MenuItem>
              <Icon className={'fa fa-bug ' + props.classes.fontIcon} />
              Diagnostics
            </MenuItem>
          </NavLink>
          <NavLink activeClassName="active-link" to="/submit" className={props.classes.link}>
            <MenuItem>
              <Icon className={'fa fa-paper-plane ' + props.classes.fontIcon} />
              Submit
            </MenuItem>
          </NavLink>
          {props.userInfo.isAuthenticated ?
            <NavLink activeClassName="active-link" to={'/profile/' + props.userInfo.uid} className={props.classes.link}>
              <MenuItem>
                <Icon className={'fa fa-user ' + props.classes.fontIcon} />
                Profile
            </MenuItem>
            </NavLink> : ''
          }
          {props.userInfo.isAuthenticated ?
            <div className={props.classes.link} onClick={(e: any) => props.onLogout()}>
              <MenuItem>
                <Icon className={'fa fa-sign-out ' + props.classes.fontIcon} />
                Logout
            </MenuItem>
            </div> :
            <NavLink activeClassName="active-link" to="/login" className={props.classes.link}>
              <MenuItem>
                <Icon className={'fa fa-sign-in ' + props.classes.fontIcon} />
                Login
            </MenuItem>
            </NavLink>

          }
        </MenuList>
      </ClickAwayListener>
    </Menu>
  );
};

const MobileMenuWithStyles = withStyles(headerStyles)(MobileMenu);

class Header extends React.Component<HeaderProps> {
  state = {
    mobileMoreAnchorEl: null
  };

  handleMobileMenuOpen = (event: any) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  }

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  }

  render() {
    const { mobileMoreAnchorEl } = this.state;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    return (
      <div className="App-title">
        <AppBar position="static" color="primary">
          <Toolbar>
            <div
              className={this.props.classes.grow}
            >
              <Link
                underline="none"
                align="left"
                className={this.props.classes.buttonLink + ' active-link'}
                href={process.env.PUBLIC_URL + '/'}
              >
                <Icon>
                  <img
                    className={this.props.classes.glueIcon}
                    title="GLUE"
                    alt="GLUE icon"
                    src="/assets/images/glue_icon_red.svg"
                  />
                </Icon>
                GLUE
              </Link>
              <Link
                underline="none"
                align="left"
                className={this.props.classes.buttonLink}
                href={'https://super.gluebenchmark.com/'}
              >
                <Icon>
                  <img
                    className={this.props.classes.superglueIcon + ' icon-white'}
                    title="SuperGLUE"
                    alt="SuperGLUE icon"
                    src="/assets/images/superglue_icon_white.svg"
                  />
                  <img
                    className={this.props.classes.superglueIcon + ' icon-red'}
                    title="SuperGLUE"
                    alt="SuperGLUE icon"
                    src="/assets/images/superglue_icon_red.svg"
                  />
                </Icon> SuperGLUE
              </Link>
            </div>
            <div className={this.props.classes.sectionDesktop}>
              <Link
                href="https://openreview.net/pdf?id=rJ4km2R5t7"
                className={this.props.classes.link}
              >
                <Icon className={'fa fa-file ' + this.props.classes.fontIcon} />
                Paper
              </Link>
              <Link
                href="https://github.com/nyu-mll/GLUE-baselines"
                className={this.props.classes.link}
              >
                <Icon className={'fa fa-code ' + this.props.classes.fontIcon} />
                Code
              </Link>
              <NavLink
                activeClassName="active-link"
                to="/tasks"
                className={this.props.classes.link}
              >
                <Icon className={'fa fa-tasks ' + this.props.classes.fontIcon} />
                Tasks
              </NavLink>
              <NavLink
                activeClassName="active-link"
                to="/leaderboard"
                className={this.props.classes.link}
              >
                <Icon className={'fa fa-trophy ' + this.props.classes.fontIcon} />
                Leaderboard
              </NavLink>
              <NavLink
                activeClassName="active-link"
                to="/faq"
                className={this.props.classes.link}
              >
                <Icon className={'fa fa-info ' + this.props.classes.fontIcon} />
                FAQ
              </NavLink>
              <NavLink
                activeClassName="active-link"
                to="/diagnostics"
                className={this.props.classes.link}
              >
                <Icon className={'fa fa-bug ' + this.props.classes.fontIcon} />
                Diagnostics
              </NavLink>
              <NavLink
                activeClassName="active-link"
                to="/submit"
                className={this.props.classes.link}
              >
                <Icon className={'fa fa-paper-plane ' + this.props.classes.fontIcon} />
                Submit
              </NavLink>
              {this.props.userInfo.isAuthenticated ?
                <NavLink
                  activeClassName="active-link"
                  to={'/profile/' + this.props.userInfo.uid}
                  className={this.props.classes.link}
                >
                  <Icon className={'fa fa-user ' + this.props.classes.fontIcon} />
                  Profile
                </NavLink> : ''
              }
              {this.props.userInfo.isAuthenticated ?
                <Link
                  className={this.props.classes.link}
                  onClick={(e: any) => this.props.onLogout()}
                >
                  <Icon className={'fa fa-sign-out ' + this.props.classes.fontIcon} />
                  Logout
                </Link> :
                <NavLink
                  activeClassName="active-link"
                  to="/login"
                  className={this.props.classes.link}
                >
                  <Icon className={'fa fa-sign-in ' + this.props.classes.fontIcon} />
                  Login
                </NavLink>
              }
            </div>
            <div className={this.props.classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                <MoreVert />
              </IconButton>
            </div>
            <MobileMenuWithStyles
              mobileMoreAnchorEl={mobileMoreAnchorEl}
              isMobileMenuOpen={isMobileMenuOpen}
              handleMenuClose={this.handleMobileMenuClose}
              {...this.props}
            />
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(headerStyles)(Header);
