import { connect } from 'react-redux';
import { StoreState } from '../types/index';
import SubmitForm from '../components/SubmitForm';
import { doCanUploadCheck } from '../actions';

const mapStateToProps = (state: StoreState) => ({
  userInfo: state.userInfo,
  submission: state.submission
});

const mapDispatchToProps = (dispatch: any) => ({
  doCanUploadCheck: () => dispatch(doCanUploadCheck())
});

const SubmitFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitForm);

export default SubmitFormContainer;
