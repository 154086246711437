import { Redirect } from 'react-router';
import * as React from 'react';

const RedirectForAdmin = (props: any) => {
  const { from } = props.location.state || { from: '/' };

  if (from && props.location.pathname !== from) {
    return (
      props.userInfo.redirectToReferrer && <Redirect to={from || '/'} />
    );
  } else {
    return (<div />);
  }
};

export default RedirectForAdmin;
