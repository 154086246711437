import * as React from 'react';
import Button from '@material-ui/core/Button';

const adaptFileEventToValue = (delegate: any) =>
  (e: any) => delegate(e.target.files[0]);

const FileInput = (props: any) => {
  const input = props.input;
  const omitValue = input.value;
  return (
    <Button
      variant="contained"
      color="primary"
      component="label"
      onClick={(e) => { return; }}
    >
      {omitValue ? omitValue.name : props.label}
      <input
        style={{ display: 'none' }}
        onChange={adaptFileEventToValue(input.onChange)}
        onBlur={adaptFileEventToValue(input.onBlur)}
        type="file"
        {...props}
      />
    </Button>
  );
};

export default FileInput;
