import * as React from 'react';
import { StoreState } from '../types/index';
import { timeSince, getRoundedScore } from '../utils/general';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  withStyles,
  Icon
} from '@material-ui/core';
import * as ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { tableStyles } from '../styles';

const loading = require('../loading.svg');

interface Props {
  userInfo: StoreState['userInfo'];
  general: StoreState['general'];
  fetchUserSubmissions: (userId: string) => Promise<void>;
  match?: {
    params: {
      uid: string;
    }
  };
  classes: any;
}

class Profile extends React.Component<Props, {}> {
  componentDidMount() {
    if (this.props.match && this.props.match.params.uid) {
      const userId = this.props.match.params.uid;
      this.props.fetchUserSubmissions(userId);
    }
  }

  getHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className={this.props.classes.th}>Model</TableCell>
          <TableCell className={this.props.classes.th}>Status</TableCell>
          <TableCell className={this.props.classes.th}>URL</TableCell>
          <TableCell className={this.props.classes.th}>Score</TableCell>
          <TableCell className={this.props.classes.th}>Created On</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  getRows = () => {
    return (
      <TableBody>
      {

        Object.keys(this.props.userInfo.submissions).map((key, index) => {
          const submission = this.props.userInfo.submissions[key];
          const isSuccessful = (typeof submission.successful === 'undefined' ?
            true : submission.successful
          );
          return (
            <TableRow
              key={key}
            >
              <TableCell className={this.props.classes.tr}>
                <Link to={'/submission/' + this.props.userInfo.uid + '/' + key}>
                  {submission.editable ? (submission.editable.name || '') : ''}
                </Link>
              </TableCell>
              <TableCell className={this.props.classes.tr}>
                <ReactTooltip effect="solid" html={true} place="right" data-multiline={true}/>
                {isSuccessful ?
                  <Icon
                    className={'fa fa-check ' + this.props.classes.successIcon}
                  /> :
                  <Icon
                    data-tip={this.getFormattedMessage(submission.message)}
                    className={'fa fa-exclamation-triangle ' + this.props.classes.errorIcon}
                  />
                }
              </TableCell>
              <TableCell className={this.props.classes.tr}>
                {submission.editable ?
                  (submission.editable.url && submission.editable.url.length > 0 ?
                  (<a href={submission.editable.url}>
                    <Icon className={'fa fa-external-link ' + this.props.classes.fontIcon}/>
                    </a>) : '') : ''}
              </TableCell>
              <TableCell className={this.props.classes.tr}>
                {isSuccessful ? getRoundedScore(submission.macroScore) : 'Failed'}
              </TableCell>
              <TableCell className={this.props.classes.tr}>
                {timeSince(submission.createdOn)}
              </TableCell>
            </TableRow>
          );
        })
      }
      </TableBody>
    );
  }

  getFormattedMessage = (message) => {
    if (!message) {
      message = '';
    }
    return message.split('\n').join('<br/>');
  }

  getSubmissionsTable = () => {
    if (this.props.userInfo.isFetchingSubmissions) {
      return (
        <div>
          <p>Fetching your submissions...</p>
          <img src={loading} className="App-logo" alt="Loading" />
        </div>
      );
    } else if (Object.keys(this.props.userInfo.submissions).length === 0) {
          return (<div>No submissions as of now. Keep crunching!</div>);
    }

    const tableHeader = this.getHeader();
    const tableRows = this.getRows();
    return (
      <Table>
          {tableHeader}
          {tableRows}
      </Table>
    );
  }

  render() {
    return (
      <div className="profile-container">
        <h1>{this.props.userInfo.displayName}'s submissions</h1>
        <p>
          (Hover over &nbsp;
          <Icon 
            style={{verticalAlign: 'text-bottom'}} 
            className={'fa fa-exclamation-triangle ' + this.props.classes.errorIcon}
          />
          for more details on failures.)
        </p>
        {this.getSubmissionsTable()}
        <br/>
      </div>
    );
  }
}

export default withStyles(tableStyles)(Profile);
