import { connect } from 'react-redux';
import { StoreState } from '../types/index';
import Leaderboard from '../components/Leaderboard';
import { fetchTasks, fetchResults, updateSelectedUser } from '../actions';

const mapStateToProps = (state: StoreState) => ({
  userInfo: state.userInfo,
  resultList: state.resultList,
  taskList: state.taskList,
  general: state.general
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchTasks: () => dispatch(fetchTasks()),
  fetchResults: (lastDay: boolean) => dispatch(fetchResults(lastDay)),
  updateSelectedUser: (index: number) => dispatch(updateSelectedUser(index))
});

const LeaderboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Leaderboard);

export default LeaderboardContainer;
