import { connect } from 'react-redux';
import { StoreState } from '../types/index';
import { authChanged, logoutUser } from '../actions/index';
import App from '../components/App';
import firebase from 'firebase';

const mapStateToProps = (state: StoreState) => ({
  userInfo: state.userInfo,
  general: state.general
});

const mapDispatchToProps = (dispatch: any) => ({
  onAuthChanged: (user: firebase.User) => dispatch(authChanged(user)),
  onLogout: () => dispatch(logoutUser())
});

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps)(App);

export default AppContainer;
