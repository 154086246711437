import { connect, Dispatch } from 'react-redux';
import { StoreState } from '../types/index';
import { TasksAction, fetchTasks } from '../actions/index';
import Tasks from '../components/Tasks';

const mapStateToProps = (state: StoreState) => ({
  taskList: state.taskList,
  userInfo: state.userInfo,
  general: state.general
});

const mapDispatchToProps = (dispatch: Dispatch<TasksAction>) => ({
  fetchTaskList: () => dispatch(fetchTasks())
});

const TaskContainer = connect(
  mapStateToProps,
  mapDispatchToProps)(Tasks);

export default TaskContainer;
