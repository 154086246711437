import * as React from 'react';
// import { Paper } from 'material-ui';
import { Redirect } from 'react-router';
// import RaisedButton from 'material-ui/RaisedButton';

const SuperGLUEContainer = () => {
    window.location.href = 'https://super.gluebenchmark.com';
    return (
        <div className="App-intro">

            <Redirect to="https://super.gluebenchmark.com"/>
            {/* <Paper zDepth={5}>
                <div className="App-intro-heading">
                    <strong>SuperGLUE</strong>
                </div>
                <div className="App-intro-content">
                    In the last year, new models and methods for pretraining and transfer
                    learning have driven striking performance improvements across a range of
                    language understanding tasks. The GLUE benchmark, introduced one year ago,
                    offered a single-number metric that summarizes progress on a diverse set of
                    such tasks, but performance on the benchmark has recently come close to the
                    level of non-expert humans, suggesting limited headroom for further research.
                    <br/>
                    <br/>
                    We take into account the lessons learnt from original GLUE benchmark and present
                    SuperGLUE, a new benchmark styled after GLUE with a new set of more difficult
                    language understanding tasks, improved resources, and a new public
                    leaderboard. SuperGLUE will be available here soon.
                </div>
                <a
                    href="https://w4ngatang.github.io/static/papers/superglue.pdf"
                    title="Manuscript"
                >
                    <RaisedButton primary={true} style={{ marginBottom: '1em' }} label="Manuscript" />
                </a>
                &nbsp;
                &nbsp;
                &nbsp;
                <a
                    href="https://urlzs.com/9nTJ"
                    title="Blog"
                >
                    <RaisedButton primary={true} style={{ marginBottom: '1em' }} label="Blog" />
                </a>
            </Paper> */}
        </div>
    );
};

export default SuperGLUEContainer;
