import * as React from 'react';
import * as showdown from 'showdown';

import * as mark from '!raw-loader!../diagnostic_details.md';

const Diagnostics = () => {
  const converter = new showdown.Converter();

  // TODO: wrap in a Paper element for consistency?
  return (
    <div className="diagnostic-page">
      <h1 style={{textAlign: 'center'}}>GLUE Diagnostic Dataset</h1>
      <div dangerouslySetInnerHTML={{__html: converter.makeHtml(mark)}}/>
    </div>
  );
};

export default Diagnostics;
