import * as React from 'react';
import { StoreState, TaskResultType, UserResultType, ResultType  } from '../types';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  withStyles,
  List,
  ListItem,
  ListItemText,
  // Typography,
  Divider,
  Icon,
  Drawer,
  Snackbar
} from '@material-ui/core';
import { STRING_CONSTANTS } from '../constants';
import * as ReactTooltip from 'react-tooltip';
import { randomString, timeSince, getPrintableConfusion, getRoundedScore, getMetricFullName } from '../utils/general';
import { linkStyle, overflowHidden,
         tableStyles, drawerStyles } from '../styles';
import NotReady from './NotReady';
import { Link } from 'react-router-dom';

const loading = require('../loading.svg');

interface Props {
  userInfo: StoreState['userInfo'];
  taskList: StoreState['taskList'];
  resultList: StoreState['resultList'];
  general: StoreState['general'];
  fetchResults: (lastDay: boolean) => void;
  fetchTasks: () => void;
  updateSelectedUser: (index: number) => void;
  classes: any;
}

type StateType = {
  drawerOpen: boolean;
  submission: ResultType | null;
  lastDay: boolean;
};

class Leaderboard extends React.Component<Props, {}> {
  state: StateType = {
    drawerOpen: false,
    submission: null,
    lastDay: false
  };

  submissions: ResultType[] = [];

  constructor(props: Props) {
    super(props);
    this.submissions = [];
  }

  getLastDayResults = (e: React.MouseEvent<HTMLInputElement>) => {
    this.submissions = [];
    this.setState({
      lastDay: e.currentTarget.checked || false
    });

    this.props.fetchResults(e.currentTarget.checked || false);
  }

  componentDidMount() {
    // First fetch tasks
    if (!this.props.taskList.tasks || Object.keys(this.props.taskList.tasks).length === 0) {
      this.props.fetchTasks();
    }
    // Now fetch results
    this.props.fetchResults(this.state.lastDay);
  }

  handleCollapseClick = (e: React.MouseEvent<HTMLDivElement>, row: number) => {
    this.props.updateSelectedUser(row);
    e.stopPropagation();
  }

  handleCellClick = (row: number, col: number) => {
    this.changeDrawerSubmission(this.submissions[row]);
  }

  changeDrawerSubmission = (submission: ResultType) => {
    this.setState({
      drawerOpen: true,
      submission: submission
    });
  }

  toggleDrawer = (open: boolean) => {
    this.setState({
      drawerOpen: open
    });
  }

  getHeader = (taskType: string, mainAuxiliary: boolean = true) => {
    const tasks = this.props.taskList.tasks;
    return (
      <TableHead>
        <TableRow>
          <TableCell className={this.props.classes.plusTh} />
          <TableCell className={this.props.classes.numberTh}>Rank</TableCell>
          <TableCell className={this.props.classes.textTh}>Name</TableCell>
          <TableCell className={this.props.classes.textTh}>Model</TableCell>
          <TableCell className={this.props.classes.th}>URL</TableCell>
          <TableCell className={'task-score-column ' + this.props.classes.numberTh}>
            Score
          </TableCell>
          {
            Object.keys(tasks).filter((key) => {
              // First filter based on the selected task type
              const task = this.props.taskList.tasks[key];

              // If type is missing, it should be primary
              if (!task.type && taskType === 'primary') {
                return true;
              }  else if (taskType === 'primary' && mainAuxiliary === true
                          && task.type === 'auxiliary' && task.auxiliaryType === 'main') {
                return true;
              } else if (task.type === taskType) {
                if (task.type === 'auxiliary') {
                  if (task.auxiliaryType === 'subcategory' && mainAuxiliary === true) {
                    return false;
                  } else {
                    return true;
                  }
                }
                return true;
              } else {
                return false;
              }
            }).map((column: string, key: number) => {
              // Generate header using original task list to handle
              // missing tasks in submissions properly, if it happens
              return (
                <TableCell
                  className={'task-score-column ' + this.props.classes.numberTh}
                  data-tip={tasks[column].name + '-' + getMetricFullName(tasks[column].metric)}
                  key={randomString()}
                >
                  {tasks[column].identifier}
                </TableCell>
              );
            })
          }
        </TableRow>
      </TableHead>
    );
  }

  getRowColumn = (result: TaskResultType, metric: string) => {
    if (metric === 'ps') {
      return result.debug.split('/').map(x => getRoundedScore(parseFloat(x))).join('/');
    }

    if (typeof(result.score) === 'undefined') {
      return '-';
    }

    if (result.score.toString() === '-') {
      return '-';
    }

    let actualScore = getRoundedScore(result.score);

    if (result.score === 0) {
      actualScore = '0.0';
    }

    if (metric.toLowerCase() === 'f1') {
      const accuracy = getRoundedScore((result.correct / result.total));
      return actualScore.toString() + '/' + accuracy.toString();
    } else {
      return actualScore;
    }
  }

  shouldDisplayStyle = (rank: number, maxScoreSubmission: boolean = false, isBaselineUser: boolean = true) => {
    return (maxScoreSubmission || isBaselineUser ||
            (this.props.resultList.selectedUser === rank &&
              this.props.resultList.selectedUserOpen))
          ? {} : {
            display: 'none'
          };
  }

  getRow = (taskType: string, result: UserResultType, submission: ResultType,
            maxScoreSubmission: boolean, rank: number, mainAuxiliary: boolean = true) => {
    this.submissions.push(submission);
    return (
      <TableRow
        style={this.shouldDisplayStyle(rank, maxScoreSubmission, result.displayName === 'GLUE Baselines')}
        key={randomString()}
        onClick={() => this.changeDrawerSubmission(submission)}
      >
        <TableCell className={this.props.classes.plusTr}>
          {
            (maxScoreSubmission && result.displayName !== 'GLUE Baselines' 
              && result.otherSubmissions && result.otherSubmissions.length > 0) ?
              this.props.resultList.selectedUserOpen &&
              this.props.resultList.selectedUser === rank ?
                <div style={linkStyle} onClick={(e) => this.handleCollapseClick(e, rank)}>
                  <Icon className={'fa fa-minus ' + this.props.classes.fontIcon}/>
                </div> :
                <div style={linkStyle} onClick={(e) => this.handleCollapseClick(e, rank)}>
                  <Icon className={'fa fa-plus ' +  this.props.classes.fontIcon}/>
                </div>
              : ''
          }
        </TableCell>
        <TableCell className={this.props.classes.tr}>
          {maxScoreSubmission ? (rank + 1) : ''}
        </TableCell>
        <TableCell  className={this.props.classes.textTr}>
          <ReactTooltip effect="solid" place="right"/>
          {
            maxScoreSubmission ?
              result.displayName
             : ''
          }
        </TableCell>
        <TableCell
          data-tip={submission.editable ? submission.editable.name : ''}
          className={this.props.classes.textTr}
        >
          <ReactTooltip effect="solid" place="right"/>
          {submission.editable ? submission.editable.name : ''}
        </TableCell>
        <TableCell className={this.props.classes.tr}>
          {submission.editable ? ((submission.editable.url && submission.editable.url.length) ?
           <a href={submission.editable.url}>
              <Icon className={'fa fa-external-link ' + this.props.classes.fontIcon}/>
           </a> : '') : ''}
        </TableCell>
        <TableCell className={'task-score-column ' + this.props.classes.numberTr}>
          {getRoundedScore(submission.macroScore || 0)}
        </TableCell>
        {
          Object.keys(this.props.taskList.tasks).filter((key) => {
            // First filter based on whether this is selected tab task or not
            const task = this.props.taskList.tasks[key];

            // If type is missing, it should be primary
            if (!task.type && taskType === 'primary') {
              return true;
            } else if (taskType === 'primary' && mainAuxiliary === true
                       && task.type === 'auxiliary' && task.auxiliaryType === 'main') {
              return true;
            } else if (task.type === taskType) {
              if (task.type === 'auxiliary') {
                if (task.auxiliaryType === 'subcategory') {
                  return false;
                } else {
                  return true;
                }
              }
              return true;
            } else {
              return false;
            }
          }).map((column: string, key: number) => {
            // Then, get the column as required
            const task = submission.tasks[column] || {};
            const metric = this.props.taskList.tasks[column].metric;
            let score = this.getRowColumn(task, metric);
            const identifier = this.props.taskList.tasks[column].identifier;

            if (identifier === 'QNLI' && parseInt(submission.createdOn, 10) < 1549027413000) {
              score = '-';
            }

            return (
              <TableCell
                className={'task-score-column ' + this.props.classes.numberTr}
                key={randomString()}
                data-tip={score}
              >
                {score}
              </TableCell>
            );
          })
        }
      </TableRow>
    );
  }

  getTable = (taskType: string = 'primary', mainAuxiliary: boolean = true) => {
    if (this.props.resultList.results.length === 0) {
      return (<div/>);
    }
    const tableHeader = this.getHeader(taskType, true);

    const tableRows = (
      <TableBody>
        {
          this.props.resultList.results.map((result: UserResultType, key) => {
            if (result.displayName !== 'GLUE Baselines') {
              result.otherSubmissions = [];
            }

            const maxRow = this.getRow(taskType, result, result.maxScoreSubmission,
                                       true, key);

            const rows: JSX.Element[] = result.otherSubmissions.map(
              (submission, submissionKey: number) => {
                return this.getRow(taskType, result,
                                   submission, false, key, mainAuxiliary);
              }
            );

            rows.unshift(maxRow);
            return rows;
          })
        }
      </TableBody>
    );

    return (
      <Table
        // bodyStyle={{overflow: 'visible'}}
      >
        {tableHeader}
        {tableRows}
      </Table>
    );
  }

  getTableTabs = () => {
    return (
      <div>
        <Snackbar
          autoHideDuration={30000}
          open={true}
          message={'Click on a submission to see more information'}
          style={{ height: 'auto', lineHeight: '28px', padding: 12, whiteSpace: 'pre-line' }}
        />
        {this.getTable('primary', true)}
        <br/>
        <br/>
      </div>
    );
  }

  getDiagnostics = (submission: ResultType | null) => {
    if (!submission) {
      return {};
    }
    const diagnostics = {};

    Object.keys(submission.tasks).forEach((id) => {
      const task = this.props.taskList.tasks[id];
      const taskScore = {...submission.tasks[id], name: task.name};

      if (task.type === 'auxiliary') {
        if (task.auxiliaryType === 'main') {
          if (!diagnostics[task.identifier]) {
            diagnostics[task.identifier] = {};
          }
          diagnostics[task.identifier].main = taskScore;
        } else if (task.auxiliaryType === 'category') {
          const main = task.identifier.substr(0, 2);

          if (!diagnostics[main]) {
            diagnostics[main] = {};
          }

          if (!diagnostics[main].categories) {
            diagnostics[main].categories = {};
          }

          if (!diagnostics[main].categories[task.identifier]) {
            diagnostics[main].categories[task.identifier] = {};
          }

          diagnostics[main].categories[task.identifier].main = taskScore;
        } else if (task.auxiliaryType === 'subcategory') {
          const main = task.identifier.substr(0, 2);

          if (!diagnostics[main]) {
            diagnostics[main] = {};
          }

          if (!diagnostics[main].categories) {
            diagnostics[main].categories = {};
          }

          const category = task.identifier.substr(0, 4);
          if (!diagnostics[main].categories[category]) {
            diagnostics[main].categories[category] = {};
          }

          if (!diagnostics[main].categories[category].categories) {
            diagnostics[main].categories[category].categories = {};
          }
          diagnostics[main].categories[category].categories[task.identifier] = taskScore;
        }
      }
    });

    return diagnostics;
  }

  getDiagnosticsList = (diagnostics) => {
    return (
        Object.keys(diagnostics).map((id) => {
          const topScore = diagnostics[id];
          const confusion = getPrintableConfusion(JSON.parse(topScore.main.confusion));
          const html = (
            <ListItem className={this.props.classes.listItem}>
              <ListItemText>{topScore.main.name} confusion matrix</ListItemText>
              <ListItemText className={this.props.classes.listItemText}>
                <table className="diagnostics-table">
                  <thead>
                    <tr><th/><th>C</th><th>N</th><th>E</th></tr>
                  </thead>
                  <tbody>
                    {
                      confusion.split('\n')
                                .map(c => <tr key={randomString()} dangerouslySetInnerHTML={{__html: c}} />)
                    }
                  </tbody>
                </table>
              </ListItemText>
              <ListItemText>C = contradiction</ListItemText>
              <ListItemText>N = not entailment</ListItemText>
              <ListItemText>E = entailment</ListItemText>
            </ListItem>
          );

          if (!topScore.categories) {
            topScore.categories = {};
          }

          const categories = Object.keys(topScore.categories).map((categoryId) => {
            const categoryScore = topScore.categories[categoryId];

            if (!categoryScore.categories) {
              categoryScore.categories = {};
            }

            const subCategoriesScores = Object.keys(categoryScore.categories).map((subId) => {
              const subCategoryScore = categoryScore.categories[subId];
              const name = subCategoryScore.name.substr(categoryScore.main.name.length).trim().replace(/-/g, '');
              return (
                <ListItem
                  className={this.props.classes.listItem}
                  key={randomString()}
                >
                  {name + ': ' + getRoundedScore(subCategoryScore.score)}
                </ListItem>
              );
            });

            const categoryHtml = (
              <ListItem
                className={this.props.classes.listItem}
                key={randomString()}
              >
                {categoryScore.main.name + ': ' + getRoundedScore(categoryScore.main.score)}
                {subCategoriesScores}
              </ListItem>
            );

            return categoryHtml;
          });

          return (
            <div key={randomString()}>
              {html}
              <Divider/>
              <b>
                <ListItem>
                    Category-wise Matthew's Correlation Scores
                </ListItem>
              </b>
              {categories}
              <Divider/>
            </div>
          );
        })
    );
  }

  getDrawerContent = () => {
    const submission = this.state.submission;
    if (!submission) {
      return <div/>;
    }
    const diagnostics = this.getDiagnostics(submission);

    return (
      <List style={{textAlign: 'left'}}>
        <b>
          <ListItem>{submission.editable.name}</ListItem>
        </b>
        <ListItem>GitHub/Model URL: &nbsp;
         {submission.editable.url ? (
          <a href={submission.editable.url}>
            <Icon className={'fa fa-external-link ' + this.props.classes.fontIconSidebar}/>
          </a>
         ) : 'N/A'}
        </ListItem>
        <ListItem>Submitted: {timeSince(submission.createdOn)}</ListItem>
        <ListItem>Score: <b>&nbsp;{getRoundedScore(submission.macroScore)}</b></ListItem>
        <ListItem style={{color: STRING_CONSTANTS.THEME[this.props.general.theme].BLUE}}>
          <Link to={'/submission/' + submission.uploaderUid + '/' + submission.submissionId}>
          More details &nbsp;
          <Icon className={'fa fa-external-link ' + this.props.classes.fontIconSidebar}/>
          </Link>
        </ListItem>
        <Divider/>
        <b><ListItem>Model Description</ListItem></b>
        <ListItem>{submission.editable.modelDescription}</ListItem>
        <Divider/>
        <b><ListItem>Parameter Description</ListItem></b>
        <ListItem>{submission.editable.parameterDescription}</ListItem>
        <Divider/>
        <ListItem><b>Total Parameters:</b>&nbsp; {submission.editable.totalParameters}</ListItem>
        <ListItem><b>Shared Parameters:</b>&nbsp; {submission.editable.sharedParameters}</ListItem>
        <Divider/>
        <ListItem><b>Diagnostics Information</b></ListItem>
        {this.getDiagnosticsList(diagnostics)}
      </List>
    );
  }

  render() {
    if (!this.props.userInfo.isSiteReady) {
      return <NotReady/>;
    }

    const StyledDrawer = withStyles(drawerStyles)(Drawer);
    return (
      <div>
        {
          (this.props.resultList.isRequested || this.props.taskList.isRequested) ?
          (<div style={overflowHidden}>
            <p>Fetching leaderboard...</p>
            <img src={loading} className="App-logo" alt="Loading" />
          </div>) :

          (
            <div>
              <StyledDrawer
                anchor="right"
                className="leaderboard-drawer"
                open={this.state.drawerOpen}
                onClose={() => this.toggleDrawer(false)}
              >

              {this.getDrawerContent()}
              </StyledDrawer>
              {this.props.userInfo.isAdmin ?
                <label>
                  <input
                    name="last_day"
                    type="checkbox"
                    checked={this.state.lastDay}
                    onClick={this.getLastDayResults}
                  />
                  Recent top 50
                </label> : ''
              }
              {this.getTableTabs()}
            </div>
          )
        }
      </div>
    );
  }
}

export default withStyles(tableStyles)(Leaderboard);
