import * as React from 'react';
import { StoreState, TaskType } from '../types/index';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  withStyles,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import * as ReactTooltip from 'react-tooltip';
import { overflowHidden, tableStyles } from '../styles';
import { getMetricFullName } from '../utils/general';
import NotReady from './NotReady';
const loading = require('../loading.svg');

interface Props {
  taskList: StoreState['taskList'];
  userInfo: StoreState['userInfo'];
  general: StoreState['general'];
  classes: any;
  fetchTaskList(): Promise<any>;
}

const tableColumns = {
  name: 'Name',
  identifier: 'Identifier',
  downloadURL: 'Download',
  infoURL: 'More Info',
  metric: 'Metric'
};

class Tasks extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.fetchTaskList();
  }

  getTasksHeader = () => {
    return (
      <TableHead>
        <TableRow>
        {
          Object.keys(tableColumns).map((column: string, key: number) => {
            return (
              <TableCell
                colSpan={column === 'name' ? 2 : 1}
                className={this.props.classes.th}
                key={column + key}
              >
                {tableColumns[column]}
              </TableCell>
            );
          })
        }
        </TableRow>
      </TableHead>
    );
  }

  getRowColumn = (task: TaskType, column: string) => {
    // TODO: Move all strings to string constants
    if (column === 'downloadURL') {
      if (task.type === 'auxiliary' && task.auxiliaryType !== 'main') {
        return '';
      }
      return (
        <a href={task[column]}>
          <Icon className={'fa fa-download ' + this.props.classes.fontIcon}/>
        </a>
      );
    } else if (column === 'infoURL') {
      if (task.type === 'auxiliary' && task.auxiliaryType !== 'main') {
        return '';
      }

      return (
        <a href={task[column]}>
            <Icon className={'fa fa-external-link ' + this.props.classes.fontIcon}/>
        </a>
      );
    } else if (column === 'metric') {
      return (task[column] ? getMetricFullName(task[column]) : '');
    } else {
      return (task[column] || '');
    }
  }

  getTaskRow = (task: TaskType, taskKey: string) => {
    return (
      <TableRow key={taskKey}>
      {
        Object.keys(tableColumns).map((column: string, key: number) => {
          return (
            <TableCell
              colSpan={column === 'name' ? 2 : 1}
              key={taskKey + key}
              className={this.props.classes.tr}
              data-tip={column === 'name' ? this.getRowColumn(task, column) : ''}
            >
              <ReactTooltip effect="solid" place="bottom"/>
              {this.getRowColumn(task, column)}
            </TableCell>
          );
        })
      }
      </TableRow>
    );
  }

  getTasksTable = (requiredTaskType: string = 'primary') => {
    if (!this.props.taskList.tasks || Object.keys(this.props.taskList.tasks).length === 0) {
      return (<div/>);
    }
    const allTaskKeys = Object.keys(this.props.taskList.tasks);
    const tableHeader = this.getTasksHeader();

    const tableRows = (
      <TableBody>
        {
          allTaskKeys.filter((taskKey: string) => {
            const taskType = this.props.taskList.tasks[taskKey].type;
            const taskId = this.props.taskList.tasks[taskKey].identifier;

            if (!taskType && requiredTaskType === 'primary') {
              return true;
            } else if (taskId === 'AX') {
              return true;
            } else if (taskType === requiredTaskType) {
              return true;
            } else {
              return false;
            }
          }).map((taskKey: string, key: number) => {
            return this.getTaskRow(this.props.taskList.tasks[taskKey], taskKey);
          })
        }
      </TableBody>
    );

    return (
      <Table className={this.props.classes.table}>
        {tableHeader}
        {tableRows}
      </Table>
    );
  }

  getTableTabs = () => {
    return this.getTasksTable();
  }

  render() {
    if (!this.props.userInfo.isSiteReady) {
      return <NotReady/>;
    }

    if (this.props.taskList.isRequested) {
      return (
        <div style={overflowHidden}>
          <p>Fetching tasks...</p>
          <img src={loading} className="App-logo" alt="Loading" />
        </div>
      );

    }
    return (
      <div className="tasks-container">
        <h2><strong>GLUE Tasks</strong></h2>
        {this.getTableTabs()}
        <br/>
        <a title="Download Script" href="https://github.com/nyu-mll/jiant/blob/master/scripts/download_glue_data.py">
          <Button color="primary" variant="contained">
            Download data
          </Button>
          <br/>
        </a>
        <br/>
        {this.props.userInfo.isAuthenticated && this.props.userInfo.isAdmin ?
          (
            <Link to="/addtask">
              <Button color="primary" variant="contained">
                Add Task
              </Button>
            </Link>
          ) : ''
        }
        <br/>
      </div>
    );
  }
}

export default withStyles(tableStyles)(Tasks);
