import * as React from 'react';
import firebase from 'firebase';
import { Route } from 'react-router';

import Login from '../containers/Login';
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import LeaderboardContainer from '../containers/LeaderboardContainer';
import About from './About';
import TasksContainer from '../containers/TasksContainer';
import SuperGLUEContainer from '../containers/SuperGLUEContainer';
import './App.css';
import Header from './Header';
import Intro from './Intro';
import Diagnostics from './Diagnostics';
import AddTaskFormContainer from '../containers/AddTaskFormContainer';
import SubmitFormContainer from '../containers/SubmitFormContainer';
import ProfileContainer from '../containers/ProfileContainer';
// import ThemeChangerContainer from '../containers/ThemeChangerContainer';
import SubmissionContainer from '../containers/SubmissionContainer';
import { AuthChangedAction } from '../actions/index';
import { auth } from '../firebase';
import { StoreState } from '../types/index';
import RedirectForAdmin from './RedirectForAdmin';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { STRING_CONSTANTS } from '../constants';
import { theme } from '../styles';

interface Props {
  userInfo: StoreState['userInfo'];
  general: StoreState['general'];
  history?: any;
  onAuthChanged(user: firebase.User | null): AuthChangedAction;
  onLogout(): any;
}

const setStyles = (selectedTheme: string) => {
  const themePallete = STRING_CONSTANTS.THEME[selectedTheme];
  document.body.style.backgroundColor = themePallete.BODY_BACKGROUND;
  document.body.style.color = themePallete.YELLOW;
};

class App extends React.Component<Props, {}> {
  componentDidMount() {
    auth.onAuthStateChanged(user => {
      this.props.onAuthChanged(user);
    });
  }

  render() {
    setStyles(this.props.general.theme);

    return (
      <MuiThemeProvider theme={theme}>

        <div className="App">
          <header className="App-header">
            <Header
              history={this.props.history}
              userInfo={this.props.userInfo}
              onLogout={this.props.onLogout}
              general={this.props.general}
            />
          </header>
          <div className="App-container">
            <Route
              exact={true}
              path="/"
              render={
                (props: any) => <RedirectForAdmin {...props} userInfo={this.props.userInfo} />}
            />
            <Route exact={true} path="/" component={Intro} />
            <PrivateRoute
              path="/submit"
              component={SubmitFormContainer}
            />
            <div className="detail-container">
              <Route
                  path="/diagnostics"
                  component={Diagnostics}
              />
              <Route
                path="/faq"
                component={About}
              />
            </div>
            <AdminRoute
              path="/addtask"
              component={AddTaskFormContainer}
            />
            <Route path="/login" component={Login} />
          </div>
          <div className="leaderboard-container">
            <Route
              path="/leaderboard"
              component={LeaderboardContainer}
            />
          </div>
          <div className="App-container">
              <Route
                path="/super"
                component={SuperGLUEContainer}
              />
          </div>
          <div className="other-container">
            <PrivateRoute
              path="/profile/:uid"
              component={ProfileContainer}
            />
            <Route
              path="/tasks"
              component={TasksContainer}
            />
            <Route
              path="/submission/:uid/:submissionId"
              component={SubmissionContainer}
            />
          </div>
          {/* <ThemeChangerContainer/> */}
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
