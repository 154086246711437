import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import { introStyles } from '../styles';

const nyuLogo = require('../ml2_logo.png');
const uwLogo = require('../uw_nlp_logo.png');
const deepmindLogo = require('../deepmind_logo.png');

const Intro: React.StatelessComponent<{}> = (props: any) => {
  return (
    <div className="App-intro">
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent'
        }}
      >
        <Grid container={true} className="App-intro-heading" alignContent="space-around" justify="space-around">
          <Grid item={true} xs={1} sm={3}/>
          <Grid className={props.classes.glueLogoContainer} item={true} xs={3} sm={2}>
            <img
              className={props.classes.glueLogo}
              title="GLUE"
              alt="GLUE logo"
              src="assets/images/glue_icon_blue.svg"
            />
          </Grid>
          <Grid className={props.classes.glueName} item={true} xs={8} sm={4}>
            GLUE
          </Grid>
          <Grid item={true} xs={1} sm={3}/>
        </Grid>

        <br />
        <Grid container={true} className={props.classes.logoContainer}>
          <Grid item={true} xs={12} sm={4} md={4} lg={4}>
            <Grid
              container={true}
              className={[props.classes.logoContainer, props.classes.firstLogo].join(' ')}
            >
              <Grid item={true} xs={10} sm={8} md={8}>
                <a className={props.classes.logo} href="https://wp.nyu.edu/ml2/" title="ML^2 NYU">
                  <img alt="ML^2 NYU" className={props.classes.logoImage} title="ML^2 NYU Logo" src={nyuLogo} />
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} sm={3} md={3} lg={3}>
            <Grid container={true} className={props.classes.logoContainer}>
              <Grid item={true} xs={10} sm={10} md={10}>
                <a className={props.classes.logo} href="https://www.cs.washington.edu/research/nlp" title="UW NLP">
                  <img alt="UW NLP" className={props.classes.logoImage} title="UW NLP Logo" src={uwLogo} />
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} sm={4} md={4} lg={4}>
            <Grid container={true} className={props.classes.logoContainer}>
              <Grid item={true} xs={10} sm={9} md={9}>
                <a className={props.classes.logo} href="https://deepmind.com/" title="DeepMind">
                  <img alt="DeepMind" className={props.classes.logoImage} title="DeepMind Logo" src={deepmindLogo} />
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} md={1} lg={1}/>
        </Grid>
        <div className="App-intro-content">
          The General Language Understanding Evaluation (GLUE) benchmark is a collection of
          resources for training, evaluating, and analyzing natural language understanding systems.
          GLUE consists of:
          <ul>
            <li>A benchmark of nine sentence- or sentence-pair language understanding tasks
            built on established existing datasets and selected to cover a diverse range of
              dataset sizes, text genres, and degrees of difficulty,</li>
            <li>A diagnostic dataset designed to evaluate and analyze model performance
              with respect to a wide range of linguistic phenomena found in natural language, and</li>
            <li>A public leaderboard for tracking performance on the benchmark and
              a dashboard for visualizing the performance of models on the diagnostic set.</li>
          </ul>

          The format of the GLUE benchmark is model-agnostic, so any system capable of
          processing sentence and sentence pairs and producing corresponding predictions
          is eligible to participate.
          The benchmark tasks are selected so as to favor models that share information across tasks
          using parameter sharing or other transfer learning techniques.
          The ultimate goal of GLUE is to drive research in the development of general and robust
          natural language understanding systems.
          <br />
          <br />
        </div>
        <Grid className={props.classes.buttonLinks} container={true} alignContent="center" justify="center">
          <a href="https://openreview.net/pdf?id=rJ4km2R5t7" title="Paper">
            <Button variant="contained" color="primary" style={{ marginBottom: '1em' }}>Paper</Button>

          </a>
          &nbsp;
          <a href="https://github.com/nyu-mll/GLUE-baselines" title="Starter Code">
            <Button variant="contained" color="primary" style={{ marginBottom: '1em' }}>Starter Code</Button>
          </a>
          &nbsp;
          <a href="https://groups.google.com/forum/#!forum/glue-benchmark-discuss" title="Google Group">
            <Button variant="contained" color="primary" style={{ marginBottom: '1em' }}>Group</Button>
          </a>
          &nbsp;
          <Link to="/diagnostics">
            <Button variant="contained" color="primary" style={{ marginBottom: '1em' }}>Diagnostics</Button>
          </Link>
        </Grid>

        <br />
        <br />
      </Paper>
      <br />
      <br />
    </div>
  );
};

export default withStyles(introStyles)(Intro);
