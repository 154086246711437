// Export a sample action name for now
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const USER_HAS_LOGGED_IN = 'USER_HAS_LOGGED_IN';
export const USER_HAS_LOGGED_OUT = 'USER_HAS_LOGGED_OUT';
export const LOGIN_WITH_GOOGLE = 'LOGIN_WITH_GOOGLE';
export const CREATE_USER = 'CREATE_USER';
export const TASKS_REQUESTED = 'TASKS_REQUESTED';
export const TASKS_FETCHED = 'TASKS_FETCHED';
export const RESULTS_REQUESTED = 'RESULTS_REQUESTED';
export const RESULTS_FETCHED = 'RESULTS_FETCHED';
export const START_CAN_UPLOAD_CHECK = 'START_CAN_UPLOAD_CHECK';
export const FINISH_CAN_UPLOAD_CHECK = 'FINISH_CAN_UPLOAD_CHECK';
export const UPDATE_SELECTED_USER = 'UPDATE_SELECTED_USER';
export const START_FETCH_USER_SUBMISSION = 'START_FETCH_USER_SUBMISSION';
export const FINISH_FETCH_USER_SUBMISSION = 'FINISH_FETCH_USER_SUBMISSION';
export const START_FETCH_SUBMISSION = 'START_FETCH_SUBMISSION';
export const FINISH_FETCH_SUBMISSION = 'FINISH_FETCH_SUBMISSION';
export const CHANGE_THEME = 'CHANGE_THEME';

export type LOGIN_USER = typeof LOGIN_USER;
export type LOGOUT_USER = typeof LOGOUT_USER;
export type USER_HAS_LOGGED_IN = typeof USER_HAS_LOGGED_IN;
export type USER_HAS_LOGGED_OUT = typeof USER_HAS_LOGGED_OUT;
export type LOGIN_WITH_GOOGLE = typeof LOGIN_WITH_GOOGLE;
export type CREATE_USER = typeof CREATE_USER;
export type TASKS_REQUESTED = typeof TASKS_REQUESTED;
export type TASKS_FETCHED = typeof TASKS_FETCHED;
export type RESULTS_REQUESTED = typeof RESULTS_REQUESTED;
export type RESULTS_FETCHED = typeof RESULTS_FETCHED;
export type START_CAN_UPLOAD_CHECK = typeof START_CAN_UPLOAD_CHECK;
export type FINISH_CAN_UPLOAD_CHECK = typeof FINISH_CAN_UPLOAD_CHECK;
export type UPDATE_SELECTED_USER = typeof UPDATE_SELECTED_USER;
export type START_FETCH_USER_SUBMISSION = typeof START_FETCH_USER_SUBMISSION;
export type FINISH_FETCH_USER_SUBMISSION = typeof FINISH_FETCH_USER_SUBMISSION;
export type START_FETCH_SUBMISSION = typeof START_FETCH_SUBMISSION;
export type FINISH_FETCH_SUBMISSION = typeof FINISH_FETCH_SUBMISSION;
export type CHANGE_THEME = typeof CHANGE_THEME;

export const STRING_CONSTANTS = {
  MTL_PROJECT_NAME: 'mtl-sentence-representations',
  PROJECT_TITLE: 'GLUE',
  AUTHENTICATION_LOCAL_STORAGE_KEY: 'MTL_SENTENCE_REPRESENTATIONS_LOCAL_STORAGE_$UID',
  SELECT_TSV_LABEL: 'Select Test TSV with labels',
  SELECT_ZIP_LABEL: 'Select Zip',
  /**
   * Background color: WHITE
   * Primary Text Color: YELLOW
   * Secondary Text Color: GREEN
   */

  // TODO: Fix up this mess
  THEME: {
    DARK: {
      WHITE: '#fff',
      TONED_WHITE: '#aaa',
      BLACK: '#000',
      RED: '#bd492a',
      YELLOW: '#efdab9',
      TABS_TEXT_COLOR: '#efdab9',
      DARK_YELLOW: '#e2ad4b',
      TABS_SELECTED_TEXT_COLOR: '#e2ad4b',
      GREEN: '#8bbbae',
      RAISED_BUTTON_COLOR: '#8bbbae',
      BLUE: '#64a2c8',
      ACCENT_1_COLOR: '#64a2c8',
      GREY: '#5c5a5b',
      ORANGE: '#d07136',
      FADE_GREY_BORDER: '#494647',
      FADE_GREY: '#393738',
      TABS_COLOR: '#393738',
      BACKGROUND_COLOR: '#343233',
      BODY_BACKGROUND: '#343233',
      GOOGLE_BUTTON_COLOR: '#fff',
    },
    LIGHT: {
      WHITE: '#fff',
      TONED_WHITE: '#aaa',
      BLACK: '#2b5283',
      RAISED_BUTTON_COLOR: '#2b5283',
      GOOGLE_BUTTON_COLOR: '#fff',
      RED: '#bd492a',
      ACCENT_1_COLOR: '#bd492a',
      YELLOW: '#222',
      TABS_TEXT_COLOR: '#aaa',
      TABS_SELECTED_TEXT_COLOR: '#fff',
      DARK_YELLOW: '#fff',
      GREEN: '#fff',
      BLUE: '#2b5283',
      GREY: '#fff',
      ORANGE: '#d07136',
      FADE_GREY_BORDER: '#205493',
      FADE_GREY: '#fff',
      TABS_COLOR: '#004C97',
      BACKGROUND_COLOR: '#2b5283',
      BODY_BACKGROUND: '#eee'
    }
  }
};
