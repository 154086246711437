import * as React from 'react';
import firebase from 'firebase';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { StoreState, ResultType } from '../types';
import { SubmissionError } from 'redux-form';
import { db } from '../firebase';
import { submitFormStyles } from '../styles';
import { withStyles } from '@material-ui/core';
import { SubmitComponent } from './Submit';

interface SubmissionEditProps {
  userInfo: StoreState['userInfo'];
}

let SubmissionEditForm: React.StatelessComponent<
  SubmissionEditProps & InjectedFormProps < {}, SubmissionEditProps >
> = (props) => {
  return (
    <SubmitComponent
      userInfo={props.userInfo}
      isEditForm={true}
      handleSubmit={props.handleSubmit}
      submitSucceeded={props.submitSucceeded}
      submitting={props.submitting}
    />
  );
};

SubmissionEditForm = reduxForm({
  form: 'submissionEditForm'
})(withStyles(submitFormStyles)(SubmissionEditForm));

interface Props {
  userInfo: StoreState['userInfo'];
  submission: ResultType;
  submissionId: string;
}

class SubmissionEdit extends React.Component<Props, {}> {
  submit = (values: any) => {

    const valuesNeeded = ['name', 'url', 'modelDescription',
    'parameterDescription', 'public'];

    // Check for all fields
    valuesNeeded.forEach((item) => {
      if (typeof(values[item]) === 'undefined') {
        throw new SubmissionError({_error: 'Missing ' + item});
      }
    });

    if (typeof(values.totalParameters) === 'undefined') {
      values.totalParameters = -1;
    }

    if (typeof(values.sharedParameters) === 'undefined') {
      values.sharedParameters = -1;
    }

    const submissionId = this.props.submissionId;
    const isPublic = values.public;
    const resultProfile = {
      name: values.name,
      modelDescription: values.modelDescription,
      url: values.url,
      parameterDescription: values.parameterDescription,
      public: !!values.public,
      totalParameters: parseInt(values.totalParameters, 10),
      sharedParameters: parseInt(values.sharedParameters, 10)
    };

    const resultVisibilityRef: firebase.database.Reference = db.ref().child(`resultsVisibility/${submissionId}`);
    const resultRef: firebase.database.Reference = db.ref().child(`results/${submissionId}/editable`);

    return Promise.all([resultVisibilityRef.set({public: isPublic}), resultRef.set(resultProfile)])
            .then(
              () => console.log('Upload successful'),
              (e) => this.throwError('Upload failed' + e)
            );

  }

  throwError = (err: string) => {
    throw new SubmissionError({_error: err});
  }

  render() {
    const submission = this.props.submission;
    const initialValues = {...submission.editable};
    initialValues.public = !!initialValues.public;

    return <SubmissionEditForm initialValues={initialValues} onSubmit={this.submit} />;
  }
}

export default SubmissionEdit;
